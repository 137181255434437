'use strict';

import Swiper from 'swiper';



let swiper = new Swiper('.header-swiper', {
    wrapperClass: 'header-swiper__wrapper',
    slideClass: 'header-swiper__slide',
    slidesPerView: 1,
    grabCursor: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: '.header-swiper__next',
        prevEl: '.header-swiper__prev',
    },
    pagination: {
        el: '.header-swiper__pagination',
        type: 'bullets',
        bulletClass: 'header-swiper__bullet',
        bulletActiveClass: 'header-swiper__bullet_active',
    },
});

let swiperLinks = new Swiper('.section-links__container', {
    wrapperClass: 'section-links__wrapper',
    slideClass: 'section-links__item',
    navigation: {
        nextEl: '.section-links__next',
        prevEl: '.section-links__prev',
    },
    slidesPerView: 2,
    spaceBetween: 100,
    loop: true,
    grabCursor: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    breakpoints: {
        876: {
            slidesPerView: 1,
            spaceBetween: 10,
        }
    }
});

/*

import your vue modules example

import Module from '../widgets/module';
const module = new Module('#module-widget');

*/
